.goal-reflections-container {
    padding: 20px;
    border: 1px solid #f4e7f4;
    border-radius: 10px;
    margin: 20px auto;
    background-color: white;
    font-family: 'Manrope';
    font-weight: 600;
}

.reflections-section-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.reflections-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 10px;
}

.reflections-textarea {
    width: 100%;
    height: 40px;
    margin: 10px 0;
    padding: 10px;
    font-family: "Manrope";
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
}

textarea:focus {
    outline: none;
    border-color: #007bff;
}

.submit-container {
    display: flex;
    justify-content: center;
    text-align: right;
    margin-top: 20px;
}

.goal-reflections-container .submit-button {
    border-radius: 8px;
    background-color: #043cf2;
    color: #ffffff;
    font-size: 16px;
    border-width: 0px;
    cursor: pointer;
    padding: 5px 20px;
    margin-top: 20px;
    margin-right: 40px;
    font-weight: 400;
    text-align: center;
    font-family: 'Open Sans';
}

.submit-button:hover {
    background-color: #0056b3;
}

