.onboarding-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  min-width: 960px;
  
}

.onboarding-card {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 26px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: content-box;
  margin-top: 4em;
  margin-bottom: 4em
}

.onboarding-content {
  padding: 48px;
  width: 100%;
  display: flex;
}

.onboarding-header-and-image {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.onboarding-text-section {
  display: flex;
  flex-direction: column;
}

.onboarding-form-top {
  display: flex;
  justify-content: space-between;
}

.onboarding-form-bottom {
  width: 100%;
  margin-top: 20px;
}

.onboarding-column-1,
.onboarding-column-2 {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.onboarding-icon-text {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.onboarding-icon-text .onboarding-button {
  background-color: white;
  color: gray;
}

.onboarding-actions {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.onboarding-note-one {
  align-self: flex-end;
}

.onboarding-note-three {
  text-align: center;
}

#stepOneImage {
  width: 446px;
  height: 418px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
}

#stepTwoImage {
  width: 298px;
  height: 318px;
  border-radius: 100000px;
  border: 1px solid #ededef;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.onboarding-icon {
  fill: #030303;
  width: 35px;
  height: 40px;
  margin-right: 8px;
}

.onboarding-text {
  color: #3d3d3d;
  font-size: 16px;
  line-height: 21px;
  margin-top: 8px;
}

.onboarding-card-title {
  font-size: 30px;
  font-weight: bold;
}

.onboarding-button {
  cursor: pointer;
  background-color: #043cf1;
  color: #ffffff;
  border: none;
  padding: 8px 40px;
  border-radius: 12px;
  margin-right: 8px;
  height: 40px;
}

.onboarding-input-field.textarea {
  height: auto;
  width: 700px;
  min-height: 75px;
  resize: vertical;
  padding: 8px 10px;
}

.onboarding-input .onboarding-text {
  margin-left: 10px;
}

.onboarding-about .onboarding-text {
  margin-left: 10px;
}

.onboarding-input-field {
  width: 324px;
  height: 53px;
  padding: 0 8px;
  margin: 10px;
  border: none;
  border-radius: 13px;
  background-color: #f3f3f5;
  color: #c2c2c2;
  font-size: 16px;
  font-family: "Manrope", sans-serif;
  line-height: 21px;
  outline: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.onboarding-input-field.filled {
  color: #000000;
}

.onboarding-select {
  width: 324px;
  height: 53px;
  padding: 0 8px;
  margin: 10px;
  border: none;
  border-radius: 13px;
  background-color: #f3f3f5;
  color: #c2c2c2;
  font-size: 16px;
  font-family: "Manrope", sans-serif;
  line-height: 21px;
  outline: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.onboarding-select.filled {
  color: #000000;
}

#profile {
  padding-left: 40%;
}

.onboarding-upload {
  position: absolute;
  top: 62%;
  right: 7%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 80px;
  border: 1px solid #ededef;
  box-sizing: border-box;
  border-radius: 32px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  color: #7b7b7b;
  background-color: #ffffff;
  outline: none;
}

.onboarding-remove {
  color: #7c7c80;
  width: 35px;
  height: 40px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.onboarding-profile-image-container {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
}

.onboarding-profile-image-container .onboarding-text {
  padding-left: 14%;
}

.error-message {
  color: red;
  font-size: 0.875em;
  margin-top: 4px;
  width: 100%;
  text-align: center;
}

.general-error-message {
  color: blue;
  font-size: 0.875em;
  margin-bottom: 10px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .onboarding-container {
    padding: 1em;
    margin-right: 4em;
    margin-left: 4em;
    
  }
}