.password-reset-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #edd7ed;
}

.password-reset-form-box {
    background-color: #fcfcfd;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 70px;
    max-width: 30em;
}

.password-reset-header {
    color: #3a3e47;
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 40px;
}

.password-reset-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.password-reset-input-field {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ededf3;
    border-radius: 5px;
    height: 30px;
}

.password-reset-input-field:focus {
    outline: none;
    border: 1px solid #117bed;
}

.password-reset-button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.password-reset-btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #117bed;
    color: white;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
}

.password-reset-signup-btn:hover {
    background-color: #0000ff;
}

.password-reset-btn:hover {
    background-color: #0000ff;
}

.password-reset-signup-btn {
    flex: 1;
}

.password-reset-footer {
    margin-top: 20px;
    font-size: 14px;
    color: #4b4e56;
}

.password-reset-error-text {
    color: red;
    font-size: 14px;
    margin: 0em;
    padding-left: 0.1em;
    display: flex;
    justify-content: center;
}

.password-reset-login-link {
    display: flex;
    justify-content: center;
    margin-top: 0.4em;
    margin-left: 0.1em;
}

.password-reset-login-link-text {
    font-size: 0.9em;
    cursor: pointer;
    text-decoration: none;
}

.password-reset-message{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-reset-link-login-link {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    margin-left: 0.1em;
}

@media screen and (max-width: 600px) {
    .password-reset-form-box {
        width: 20em;
        padding: 2em;
        box-sizing: border-box;
    }
}