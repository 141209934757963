/* Suggested Resources Card Styles */

.suggested-resources-card {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #f4e7f4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 20px;
    box-sizing: border-box;
    min-width: 378px;
    max-height: 1150px;
}
