/* Strengths Growth Card Styles */

.strengths-growth-card {
    gap: 20px;
    min-width: 540px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #f4e7f4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 20px;
    margin-bottom: 20px;
    flex-grow: 1;
    box-sizing: border-box;
}

.strengths-growth-inner-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.strengths-card-inner, .growth-card-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.strengths-title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 136px;
    min-height: 36px;
    padding: 0 8px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    background-color: #68C157;
    color: #000000;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    text-align: center;
}

.growth-title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 136px;
    min-height: 36px;
    padding: 0 8px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    background-color: #ff9e3a;
    color: #000000;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    text-align: center;
}

.growth-list {
    min-width: 100px;
    height: 100%;
    color: #ffcc00;
    font-size: 14px;
    font-family: Manrope;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.strengths-list {
    min-width: 100px;
    height: 100%;
    color: #68C157;
    font-size: 14px;
    font-family: Manrope;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.growth-item {
    border-radius: 10px;
    border: 1px solid #ff9e3a;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);;
    padding: 10px;
}

.strengths-item {
    border-radius: 10px;
    border: 1px solid #68C157;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);;
    padding: 10px;
}

