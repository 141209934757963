.milestone-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 300px;
  }
  
  .milestone-modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    width: 1000px;
    max-width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    font-family: "Manrope", sans-serif;
  }
  
  .milestone-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: "Manrope", sans-serif;
    padding-bottom: 50px;
    position: relative;
  }
  
  .milestone-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    font-family: "Manrope", sans-serif;
  }
  
  .milestone-save-button {
    appearance: none; 
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    background-color: #043cf2; 
    color: white; 
    border: none; 
    border-radius: 10px; 
    padding: 6px 50px;
    margin-right: 40px;
    cursor: pointer; 
    text-align: center; 
    width: auto; 
  }
  
  .button-container {
      text-align: right;
      margin-top: 10px; 
  }
  
  .milestone-save-button:hover {
    background-color: #3730a3;
  }
  