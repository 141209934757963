.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #edd7ed;
}

.signup-form-box {
  background-color: #fcfcfd;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  padding: 70px;
}

.signup-header {
  color: #3a3e47;
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 40px;
}

.signup-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.signup-input-field {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ededf3;
  border-radius: 5px;
  height: 30px;
}

.signup-input-field:focus {
  outline: none;
  border: 1px solid #117bed;
}

.signup-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.signup-signup-btn,
.signup-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #117bed;
  color: white;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}

.signup-signup-btn:hover {
  background-color: #0000ff;
}

.signup-btn:hover {
  background-color: #0000ff;
}

.signup-signup-btn {
  flex: 1;
}

.signup-btn {
  flex: 1;
  margin-right: 40px;
}

.signup-footer {
  margin-top: 20px;
  font-size: 14px;
  color: #4b4e56;
}

.signup-error-text {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .signup-form-box {
    width: 20em;
    padding: 2em;
    box-sizing: border-box;
  }
}