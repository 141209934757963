.ask-staffy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px;
}

.ask-staffy-main {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 95%;
  background-color: #fff;
  border-radius: 26px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.ask-staffy-header {
  padding: 20px;
  background-color: gray;
  color: white;
  text-align: center;
}

.ask-staffy-header h1 {
  margin: 0;
  font-size: 2rem;
}

.ask-staffy-header p {
  margin-top: 10px;
  font-size: 1rem;
}

.ask-staffy-chat {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

