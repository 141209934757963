/* GENERAL */

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

:root {
    /* Font styling */
    --font-family: "Manrope",
        sans-serif;
    --color: #455071;


    /* Colors */
    --highlighting-background-color: #f4e7f4;

}

.self-assessment-container {
    width: 90%;
    max-width: 1200px;
    margin: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* INSTRUCTIONS */


.instructions-container,
.self-assessment-form-container {
    margin: 2em auto 2em auto;
    padding-bottom: 1.5em;
    border-radius: 1.625rem;
    box-shadow: 0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.16);
    color: var(--color);
    width: 90%;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.self-assessment-alignment {
    width: 90%;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

.instructions-title {
    font-weight: 500;
}

.instructions-text {
    font-size: 1rem;
    line-height: 1.6;
}

.self-assessment-rating-list {
    list-style-type: none;
}

/* IMAGE */

.self-assessment-img-container-comp {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: first center;
    overflow: hidden;
    flex: 1;
    height: 20%;
}

.self-assessment-img-container-comp img {
    width: 40%;
    height: auto;
    object-fit: cover;
}

/* BUTTON */
.self-assessment-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.self-assessment-btn {
    cursor: pointer;
    color: #372ecf;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    outline: none;
    box-shadow: -0.125rem 0.125rem 0rem rgba(0,
        0,
        0,
        0.16);
    ;
    background-color: rgba(55, 46, 207, 0.18);
    border-radius: 0.6875rem;
    border: 0;
    height: 2.6875rem;
    padding: 0 2rem;
}

.container {
    display: flex;
    direction: row;
    justify-content: center;
}

.parent {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    min-width: 0;
}


.survey-btn-container .survey-btn:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.survey-loading-spinner {
    display: flex;
    align-items: center;
    width: 100%;
}

.survey-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-left-color: #0000ff;
    margin-right: 8px;
    margin-left: 2rem;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}