/* FBReport Background */
.fb-report-bg {
    display: flex;
    justify-content: center;
    margin-top: 4em;
    margin-bottom: 4em;
    max-width: 1200px;
    min-width: 960px;
    padding: 1em;
}

.fb-report-content-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.fb-report-temp-navbar {
    width: 12em;
    height: 100vh;
    background-color: rgb(206, 205, 205);
    position: fixed;
    top: 0;
    text-align: center;
    line-height: 100vh;
}

.fb-report-temp-top-nav {
    width: 100vw;
    height: 2em;
    background-color: rgb(206, 205, 205);
    position: fixed;
    top: 0;
    text-align: center;
    /* Center the text horizontally */
}

/* FBReport Image */
.fb-report-intro-image-container {
    height: 15em;
    margin-top: -5em;
    align-self: flex-end;
}

.fb-report-intro-image-container img {
    height: 24em;
}

/* FBReport Title */
.fb-report-title-container {
    display: flex;
    flex-direction: row;
    min-height: 3em;
}

.fb-report-title {
    font-size: 2em;
    margin-right: 0.25em;
}

.fb-report-icon-container {
    height: 2em;
    border-radius: 8px;
    position: relative;
}

.fb-report-icon-container svg {
position: absolute;
top: 0.9em;
cursor: pointer;
}

/* FBReport Intro */
.fb-report-intro {
    padding-left: 0.5em;
    color: #455071;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6em;
}

.fb-report-reviewer-info span {
    margin-right: 0.5em;
}

.fb-report-reviewer-info {
    margin-bottom: 1em;
}

/* FBReport Competency */
.fb-report-competency-container {
    display: flex;
    flex-direction: column;

}

/* FBReport Competency Components start */

/* Competency Graph Title */
.fb-report-competency-graph-title {
    height: 2em;
    width: 100%;
    font-size: 2em;
    text-align: center;
    font-weight: 400;
    line-height: 2em;
    margin-bottom: 1em;
}

/* Competency Graph */
.fb-report-competency-graph-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fb-report-competency-graph-container-radar {
    width: 80%;
    height: 60%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    }

.fb-report-radar-chart {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.fb-report-competency-graph-container-bar {
    width: 90%;
    margin-top:-2em;
    background-color: #ffffff;
    border-radius: 26px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 2.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 2em;
}



/* Competency Top Three */
.fb-report-competency-top-three-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1em;
}

.fb-report-competency-top-three-categories-container {
    width: 18em;
    height: 14em;
    margin-right: 2em;
    border-radius: 26px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 2em;
}

.fb-report-competency-top-three-title {
    font-size: 2em;
    margin-bottom: 0.5em;
}

.fb-report-competency-attributes {
    margin-left: 1em;
    display: flex;
    flex-direction: column;
}

.fb-report-competency-attribute {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    align-items: center;
}

.fb-report-competency-strength-name {
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #4cd964;
    margin-right: 2em;
    width: 14em;
    height: 2em;
    line-height: 2em;
    text-align: center;

}

.fb-report-competency-level {
    height: 2em;
    line-height: 1.5em;
    text-align: center;
    font-weight: 400;
    font-size: 1.5em;
    line-height: 2em;
}

.fb-report-competency-growth-area-name {
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #ff9500;
    margin-right: 2em;
    width: 14em;
    height: 2em;
    line-height: 2em;
    text-align: center;
}

/* Competency Overlooked */
.fb-report-competency-overlooked-container {
    display: flex;
    flex-direction: column;
}

.fb-report-overlooked-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
}

.fb-report-overlooked-container {
    width: 55%;
    border-radius: 26px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 2em;
}

.fb-report-overlooked-attribute-container {
    display: flex;
    flex-direction: column;
}

.fb-report-overlooked-title {
    font-size: 2em;
    margin-bottom: 0.5em;
}

.fb-report-overlooked {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    align-items: center;
}

.fb-report-overlooked-label{
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    margin-right: 2.5em;
    width: 26em;
    height: 2em;
    text-align: center;
    font-size: 1em;
    line-height: 2em;
}

.fb-report-overlooked-value-container{
    display: flex;
    flex-direction: row;
}

.fb-report-overlooked-value{
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    background-color: #c2c2c2;
    border-radius: 8px;
    text-align: center;
    font-size: 1.5em;
    line-height: 2em;
}

.fb-report-overlooked-image-container {
    width: 30%;
    height: 16em;

}

.fb-report-overlooked-image-container img {
    height: 120%;
    margin-top: -1.5em;
}

.blind-spot-img {
    margin-left: 2.5em
}

.hidden-strengths-img {
    margin-left: -1em
}

/* FBReport Competency Components end */

/* FBReport Analysis */
.fb-report-analysis-container{
    display: flex;
    flex-direction: column;
}

/* FBReport Analysis Components start */

/* Analysis General */
.fb-report-analysis-general-container{
    border-radius: 26px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    margin-bottom: 1em;
    padding: 2em;
}

.fb-report-analysis-general-label{
    font-size: 2em;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0.5em;
}

/* Analysis Goals */
.fb-report-analysis-goals-container{
    display: flex;
    flex-direction: column;
}

.fb-report-analysis-goals-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
}

.fb-report-analysis-goals-card{
    border-radius: 26px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    margin-right: 1em;
    width: 50%;
    padding: 2em;
}

.fb-report-analysis-goals-card:last-child {
    margin-right: 0em;
}

.fb-report-analysis-goals-topic{
    font-size: 2em;
    margin-bottom: 1em;
    text-align: center;
}

.fb-report-analysis-goals-content{
    margin-left: 2em;
}

@media screen and (max-width: 600px) {
    .fb-report-radar-chart {
        width: 100%;
        height: 30em;
    }

    .fb-report-bg {
        padding: 1em;
    }
}



@media print {
    body {  
        contain: content;
    }

    .app-main-content {
        border: blue solid 1px !important;
        background-color: red;
    }

    .fb-report-bg{
        border: #455071 solid 1px;
        padding-left: 0px;
        background-color: red;
    }

    .fb-report-content-container{
        border: #455071 solid 1px;
        width: 100vw;
        margin-left: 0px;
    }

        .fb-report-competency-graph-section {
            border: #455071 solid 1px;
        }

    
    .fb-report-competency-graph-container-radar {
        margin-bottom: 10vh;
        border: #455071 solid 1px;
        margin-left: 0px;
        width: 10em;
        height: 10em;
    }
/*
    .fb-report-competency-graph-section {
        margin-bottom: 40vh;
    }

    .fb-report-competency-overlooked-container {
        /* margin-bottom: 10vh; 
    }

    .fb-report-analysis-container {
        margin-top: 10vh;
    }

    .fb-report-analysis-goals-container {
        margin-top: 10vh;
    }

    /* .sidebar {
        display: none;
    } */
}