.goal-and-image-container {
  display: grid;
  grid-template-columns: 1fr auto; 
  grid-gap: 20px;
  align-items: start; 
  width: 100%;
}

.goal-content {
  position: relative;
  padding: 20px;

  background-color: #fff;
}

.goal-percentage-circle {
  position: absolute;
  top: -15px; 
  right: -15px; 
  width: 65px; 
  height: 65px; 
  background-color: #cdf2d0; 
  color: black;
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Manrope', sans-serif;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.circle-low {
  background-color: #f2cdd2;
}

.circle-medium {
  background-color: #f1d7ba;
}

.circle-high {
  background-color: #cdf2d0; 
}

.goal-side-image {
  width: 350px;
  height: auto;
}

.goal-info {
  padding: 20px;
  flex: 1;
  font-family: 'Manrope', sans-serif;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.goal-container {
  font-family: 'Manrope', sans-serif;
  padding: 20px;
  margin-top: 20px;
  margin-left: 20px;
}

.goal-title {
  font-size: 18px;
  font-weight: bold;
}

.goal-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.milestone-goal-image {
  width: 236px;
  height: 236px;
  margin-right: 20px;
}

.goal-reflections-container {
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  margin-top: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.goal-reflections-container h2 {
  color: #333;
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  height: 50px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  resize: vertical;
  box-sizing: border-box;
}

textarea:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
    0 0 8px rgba(74, 144, 226, 0.5);
}

.goal-content {
  position: relative; 
}

.goal-content h2 {
  margin-left: 20px;
  margin-top: 0px;
}

/* ----------------------------------------------------- */
.milestones-section-container {
  position: relative;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.custom-symbol-size {
  font-size: 35px;
}

.milestones-title {
  margin-top: 2px;
  margin-left: 7px;
  font-weight: bold;
  font-size: 20px;
}

.milestones-section-header {
  position: absolute; 
  top: -50px; 
  left: 0; 
  width: 100%; 
  padding-bottom: 10px;
  z-index: 1; 
}

.reflections-section-header {
  display: inline-flex;
  padding-bottom: 10px;
}

/* Modal Styles */
.goal-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 300px;
}

.goal-close-button-wrapper {
  width: 80%;
  height: 80%;
  overflow-y: auto;
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.goal-modal-close-button {
  padding: 8px 16px;
  background-color: #117bed;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

.goal-modal-close-button:hover {
  background-color: #0000ff;
}
