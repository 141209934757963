.insights-performance-container {
    height: 100%;
    width: auto;
    margin: 20px;
}

.insight-performance-gauge-name {
    text-align: center;
    font-size: x-large;
}

.insight-performance-gauge-text {
    padding-bottom: 10px;
}

.insights-performance-card {
    display: flex;
    height: auto;
    width: auto;
    justify-content: space-evenly;
}

.insight-performance-gauge-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 1200px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.insight-performance-gauge-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 275px;
    height: 275px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
}

.insights-performance-card-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.insights-performance-text-large {
    color: #455071;
    font-size: 32px;
    font-family: "Manrope", serif;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 20px;
    margin-right: 5px;
}

.insights-performance-text-small {
    color: #455071;
    font-size: 24px;
    font-family: "Manrope", serif;
    line-height: 31px;
}

.insights-performance-image-container {
    min-width: 457px;
    min-height: 343px;
    border-radius: 8px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
