.milestones-grid-two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
}

.milestones-grid-one-column {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
}

.no-milestones-prompt {
  text-align: center;
  padding: 20px;
  color: rgba(3,3,3,0.5);
  border: 1px solid #f4e7f4;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.create-milestone-button {
  background-color: #043cf2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.milestone-inside {
  font-family: "Manrope", sans-serif;
}

.create-milestone-button:hover {
  background-color: #3700b3;
}

.create-milestone-link {
  color: #494949;
  cursor: pointer;
  margin-top: 10px;
  display: inline-block;
}

.create-milestone-link:hover {
  color: #202020;
}

.milestone-inside {
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  background-color: white;
  border-radius: 8px;
}

.milestone-mark-completed-button {
  padding: 8px 16px;
  background-color: #117bed;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.milestone-modal-close-button {
  background: none;
  border: none;
  color: #888;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  text-align: right;
}

.edit-symbol {
  font-size: 16px;
  color:#494949;
  cursor: pointer;
}

.milestone-status-dropdown {
  appearance: none; 
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  background-color: #043cf2; 
  color: white; 
  border: none; 
  border-radius: 10px; 
  padding: 6px 30px;
  cursor: pointer; 
  text-align: center; 
  width: auto; 
}

.milestone-status-dropdown.not-started {
  background-color: #043cf2; 
}

.milestone-status-dropdown.completed {
  background-color: #b4e1b6;
  color: black;
}

.milestone-status-dropdown:focus {
  outline: none; 
  box-shadow: 0 0 4px 2px rgba(0, 123, 255, 0.5); 
}

.milestone-status-dropdown option {
  background-color: white; 
  color: black; 
}