/* Goals BG */
.goals-bg {
  display: flex;
  justify-content: center;
  margin-bottom: 4em;
}

.goals-content-container {
  width: 60em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
}

.goals-temp-navbar {
  width: 12em;
  height: 100vh;
  background-color: rgb(206, 205, 205);
  position: fixed;
  top: 0;
  text-align: center;
  line-height: 100vh;
}

.goals-temp-top-nav {
  width: 100vw;
  height: 2em;
  background-color: rgb(206, 205, 205);
  position: fixed;
  top: 0;
  text-align: center;
  /* Center the text horizontally */
}

.goals-header {
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 1em;
}

/* Goals Image */
.goals-img-container {
  width: 100%;
  height: 20em;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.goals-img-container img {
  height: 100%;
  object-fit: cover;
}

/* Goals Creation */
.goals-creation-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.goals-creation-container > * {
  display: block;
}

.goals-text-input {
  display: block;
  width: 100%;
  min-height: 2.4em;
  border: 1px solid #d6d6db;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  background-color: rgba(255, 255, 255, 0.87);
  color: #455071;
  outline: none;
  margin-bottom: 6px;
  padding-left: 0.5em;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  padding-right: 3px;
}

.goals-description-input {
  height: 7.2em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.goals-text-input::placeholder {
  color: #888888;
}

.goals-description-input::placeholder {
  color: #888888;
  font-family: Arial, Helvetica, sans-serif;
}

.goals-input-fields-container {
  margin-bottom: 0.5em;
}

.create-goals-button {
  height: 2.5em;
  width: 8em;
  border-radius: 40px;
  background-color: #043cf2;
  color: #ffffff;
  border-width: 0px;
  cursor: pointer;
  margin-top: 20px;
}

.goals-complete-status {
  margin-right: 2em;
  color: green;
}

.goals-error-text {
  color: red;
  margin-left: 0.5em;
  padding-top: 0.5em;
}

/* Goals Scale */

.goals-scale-dropdown {
  display: flex;
  width: 100%;
  height: 2em;
  border: 1px solid #d6d6db;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  background-color: rgba(255, 255, 255, 0.87);
  font-size: 0.9em;
  line-height: 2em;
  outline: none;
  justify-content: space-between;
  box-sizing: border-box;
  transition: border-radius 0.2s ease;
  padding-left: 0.5em;
  cursor: pointer;
}

.goals-scale-dropdown.active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.goals-scale-placeholder {
  color: #888888;
}

.goals-scale-value {
  font-size: 0.9em;
  line-height: 2em;
}

.goals-scale-dropdown-btn {
  font-size: 1.5em;
  line-height: 1.5em;
  cursor: pointer;
}

.goals-dropdown-rotate {
  transform: rotate(180deg);
}

/* Goals Scale Options */
.goals-scale-options {
  height: 2.2em;
  line-height: 2.2em;
  padding-left: 0.5em;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  background-color: rgba(255, 255, 255, 0.87);
  width: 100%;
  box-sizing: border-box;
  font-size: 0.9em;
  cursor: pointer;
}

.goals-scale-options:hover {
  background-color: #f8f8f8;
}

.goals-scale-options.active {
  background-color: #f8f8f8;
}

/* Goals Tracking */
.goals-tracking-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1em;
}

.goals-tracking-card {
  width: 100%;
  height: 4em;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #dedede;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  margin-bottom: 0.6em;
}
.goals-tracking-card-text {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.goals-tracking-task {
  display: block;
  color: #030303;
  font-size: 1em;
  font-weight: 600;
}

.goals-tracking-deadline {
  display: block;
}

.goals-tracking-card-buttons-container {
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.goals-tracking-card-complete {
  height: 2.5em;
  cursor: pointer;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 40px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  background-color: #043cf2;
  color: #ffffff;
  outline: none;
  margin-right: 1em;
  padding-left: 1em;
  padding-right: 1em;
}

.goals-tracking-card-edit {
  height: 2.5em;
  cursor: pointer;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 40px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  background-color: #043cf2;
  color: #ffffff;
  outline: none;
  margin-right: 2em;
  padding-left: 1em;
  padding-right: 1em;
}

@media screen and (max-width: 600px) {
  .goals-bg {
    margin-left: 2em;
  }
}
