.goal-notes-container {
    padding: 20px;
    border: 1px solid #f4e7f4;
    border-radius: 10px;
    margin: 20px auto;
    background-color: white;
    font-family: 'Manrope';
    font-weight: 400;
}

.notes-section-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.notes-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 10px;
}

.notes-textarea {
    width: 100%;
    height: 90px;
    margin: 10px 0;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #d6d6db;
    border-radius: 12px;
    resize: none;
    font-family: "Manrope";
}

textarea::placeholder {
    font-family: "Manrope";
    font-weight: 400;
    color: #c0c0c0; 
    font-size: 16px;
}

textarea:focus {
    outline: none;
    border-color: #007bff;
}

.submit-container {
    display: flex;
    justify-content: center;
}

.goal-notes-container .submit-button {
    border-radius: 8px;
    background-color: #043cf2;
    color: #ffffff;
    font-size: 16px;
    border-width: 0px;
    cursor: pointer;
    padding: 5px 20px;
    font-weight: 400;
    text-align: center;
    font-family: 'Open Sans';
}

.submit-button:hover {
    background-color: #0056b3;
}

.separator-line {
    border: 0;
    height: 1px;
    background: #ccc;
    margin: 20px 0;
}

.saved-notes-container {
    margin-top: 20px;
}

.note-entry {
    margin-bottom: 20px;
    padding: 15px;
}

.note-content {
    font-size: 1rem;
    margin-bottom: 10px;
}

.note-date {
    font-size: 12px;
    color: #7f7f7f;
}