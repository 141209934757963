/* Feedback Summary Card Styles */

.feedback-summary-card {
    min-width: 1105px;
    height: 477px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #f4e7f4;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
    padding: 40px 20px 20px 20px;
    margin-bottom: 20px;
    flex-grow: 1;
    box-sizing: border-box;
}
