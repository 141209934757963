.switch-view-parent {
    padding-bottom: 30px;
}

.switch-view-greeting {
    display: flex;
    width: 45%;
}

.switch-view-text {
    font-size: xx-large;
    font-family: Arial, Helvetica, sans-serif;
    padding-right: 15px;
}

.switch-view-buttons {
    display: flex;
    justify-content: space-between;
    width: 35%;
}

.switch-view-img {
    padding-right: 15px;
}

.switch-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
}

.left-label,
.right-label {
    font-size: 14px;
    font-weight: 400;
    color: #757575;
    margin: 0 10px;
}

.switch-toggle {
    position: relative;
    width: 100px;
    height: 30px;
    background-color: #e6e6e6;
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    margin-left: 50px;
    margin-right: 50px;
}

input[type="radio"] {
    display: none;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #333;
    border-radius: 30px;
    transition: all 0.3s ease;
}

.performance {
    left: 0;
}

.feedback {
    left: 50%;
}

.switch-view-icon {
    color: #3d3d3d;
    fill: #3d3d3d;
    font-size: 60px;
    top: 102px;
    left: 693px;
    width: 60px;
    height: 55px;
}