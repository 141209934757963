.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-radius: 26px;
  background-color: #fff;
  overflow: hidden;
}

/* Chat Message Styling */
.chat-message {
  display: flex;
  margin-left: 45px;
  margin-bottom: 15px;
}

.message-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  max-width: 80%;
}

.profile-pic {
  width: 45px;
  height: 45px;
  margin-top: 10px;
  border-radius: 50%;
  object-fit: cover;
}

/* Message Content Styling */
.message-content-container {
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  word-wrap: break-word;
  max-width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}

.chat-history {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #fff;
}

/* Message Header Styling */
.message-header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  gap: 25px;
  font-size: 0.9rem;
  color: #666;
}

.message-sender {
  font-weight: bold;
  color: #333;
}

.message-timestamp {
  font-size: 0.8rem;
  color: gray;
}

/* Message Text */
.message-content {
  margin: 0;
  color: #000;
}

/* Chat Input Container */
.chat-input-container {
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  padding: 8px;
  border-radius: 10px;
  margin: 40px;
  background-color: #fff;
  font-family: "Manrope";
}

/* Textarea Styling */
.chat-input {
  width: 100%;
  height: 80px;
  border: none;
  resize: none;
  font-size: 1rem;
  padding: 10px;
  box-shadow: none;
  outline: none;
  font-family: "Manrope";
}

.chat-input:focus {
  box-shadow: none;
  outline: none;
}

.chat-input-container .separator-line {
  border: 0;
  height: 1px;
  background: #ccc;
  margin: 10px;
}

/* Actions Row Styling */
.chat-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

/* Buttons Styling */
.attachment-button,
.send-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #7c7c80;
}

.attachment-button span,
.send-button span {
  font-size: 24px;
  color: #555;
}

.attachment-button:hover span,
.send-button:hover span {
  color: #007bff;
}

/* Rotated Icon */
.rotated-icon {
  transform: rotate(45deg);
}

.rotated-icon-rev {
  transform: rotate(-45deg);
}

.date-separator {
  text-align: center;
  font-size: 0.9rem;
  color: #3d3d3d;
  background-color: #f1f1f1;
  border-radius: 10px;
  margin: 10px 40px;
  padding: 5px 10px;
}
