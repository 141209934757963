/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


/* ANSWER BUTTONS */
.answer-selected {
    background-color: rgba(4, 60, 241, 1);
    color: #ffffff;
}

.answer-unselected {
    background-color: rgba(244, 231, 244, 0.6);
    color: #000000;
}

.answer-unselected,
.answer-selected {
    font-weight: 0;
    font-family: "Manrope",
        sans-serif;
    font-size: 0.8rem;
    line-height: 1.2rem;
    padding-left: 0.7em;
    padding-right: 0.7em;
    border-radius: 0.75rem;
    border: 0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
    /* margin: 1em 0.5em 0.5em 1em; */
    margin-left: 0.7em;
    margin-top: 1em;
    width: 9em;
    /* Fixed width for uniform button size */
    height: 3.7rem;
    /* Fixed height for uniform button size */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

/* TEXT */
.survey-category,
.survey-sub-category,
.survey-sub-sub-category,
.survey-question {
    font-family: "Manrope",
            sans-serif;
}

.survey-category {
    font-weight: 500;
    background-color: #f4e7f4;
    font-size: 1.5rem;
    padding-left: 1em;
    color: #000000;
    line-height: 3rem;
    margin-top: 1.2em;
}

.survey-sub-category {
    font-weight: 500;
    font-size: 1.5rem;
    padding-left: 0.5em;
    padding-top: 0.5em;
    color: #3d3d3d;
}

.survey-sub-sub-category {
    font-weight: 500;
    font-size: 1.2rem;
    padding-left: 0.5em;
    color: #3d3d3d;
}

.survey-question {
    font-weight: 500;
    font-size: 1.1rem;
    padding-left: 2.5em;
    padding-right: 2.5em;
    padding-top: 0.5em;
    color: #3d3d3d;
}

.sub-question {
    font-weight: 500;
    font-size: 1.1rem;
    padding-left: 3.5em;
    padding-right: 2.5em;
    color: #3d3d3d;
}

.survey-question input,
.survey-question textarea,
.sub-question input,
.sub-question textarea {
    font-weight: 0;
    font-family: "Manrope",
        sans-serif;
    font-size: 0.8rem;
    padding-left: 2.5em;
    padding-right: 2.5em;
    border-radius: 0.75rem;
    border: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    background-color: #f9f9f9;;
    width: 90%;
}

.survey-question input,
.sub-question input{
    line-height: 3rem;
}

.survey-question textarea,
.sub-question textarea {
    line-height: 2rem;
}

/* FORM */
.survey-btn-container {
   width: 90%;
   margin: 2em auto 1em auto;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.survey-btn-container .survey-btn { 
    font-family: 'Manrope', sans-serif;
    cursor: pointer;
    color: #fff;
    font-size: 0.9375rem;
    font-weight: 600;
    text-align: center;
    outline: none;
    box-shadow: -0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.25);
    ;
    background-color: rgba(4, 60, 241, 1);
    border-radius: 0.6875rem;
    border: 0;
    height: 2.6875rem;
    padding: 0 1.2rem;
    width: 30%;
}

/* Container for buttons to display them in rows and columns */
.survey-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.survey-question .survey-button-container,
.sub-question .survey-button-container {
    padding: 0 0.5em;
    margin-bottom: 0.5em;
}

.survey-question p,
.sub-question p {
    margin-bottom: 0;
}

.group-sub-category,
.group-sub-sub-category {
    margin-top: 5rem;
    margin-bottom: 0.5rem;
}