/* Growth Plan Card Styles */

.growth-plan-card {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #f4e7f4;
    flex-grow: 1;
    flex-shrink: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 20px;
    box-sizing: border-box;
    min-width: 378px;
    max-height: 846px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: normal;
    overflow: hidden;
}

.growth-plan-card a {
    word-break: break-all;
    color: #0066cc;
    text-decoration: underline;
}
