/* Peer Review */
/* BG */
.reviewer-bg {
    padding-left: 5em;
    padding-right: 5em;
    display: flex;
    flex-direction: column;
    margin-top: 4em;
    box-sizing: border-box;
}

/* Table */
.reviewer-table-img-container {
    height: 9em;
    display: flex;
    justify-content: end;
    padding-right: 6em;
}

.reviewer-table-img-container img {
    height: 200%;
    margin-top: -2.5em;
}

.reviewer-tr {
    padding-left: 4em;
}

.reviewer-th {
    padding-left: 4em;
}

.reviewer-td {
    padding-left: 4em;
}

.reviewer-survey-requested {
    height: 2em;
    width: 2em;
    margin-left: 2em;
}

/* Banner */
.reviewer-banner {
    width: 100%;
    height: 5em;
    background-color: #eddeed;
    border: 1px solid #eddfed;
    box-sizing: border-box;
}

.reviewer-banner-contents{
    display: flex;
    flex-direction: row;
    height: 100%;
}

.reviewer-banner-logo {
    width: 10%;
}

.reviewer-banner-logo img {
    height: 100%;
}

.reviewer-banner-text {
    font-size: 1.5em;
    line-height: 3em;
    width: 75%;
}

.reviewer-banner-chat{
    width: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reviewer-chat-icon-container{
    height: 2em;
    width: 2em;
    margin-right: 1em;
}

.reviewer-chat-text{
    font-size: 1em;
}

/* Instructions */
.peer-feedback-instructions-image-container{
    width: 100%;
    height: 18em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5em;
}

.peer-feedback-instructions-image-container img{
    height: 100%;
}

.peer-feedback-instructions-container {
    display: flex;
    flex-direction: column;
}

.peer-feedback-instructions-container > .instructions-container {
    width: 75%;
    margin-top: 0em;
}

@media screen and (max-width: 600px) {
    .reviewer-bg {
        padding: 0em;
    }

    .reviewer-banner-logo {
        width: 20%;
    }

    .reviewer-banner-contents {
        justify-content: space-between;
    }

    .reviewer-banner-chat {
        width: 60%;
        justify-content: end;
        padding-right: 1em;
    }

    .reviewer-banner-text {
        display: none;
    }

    .reviewer-table-container.table-container {
        overflow-x: auto;
    }
}