/* ========== Global Card Styles ========== */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.insights-title-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    padding-top: 30px;
}

.insights-container {
    width: 100%;
    padding: 20px 40px 20px 40px;
    box-sizing: border-box;
}

.second-row, .third-row, .fourth-row {
    display: grid;
    width: 100%;
    gap: 20px;
}

.second-row {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 20px;
}

.third-row {
    grid-template-columns: 2fr 1fr;
}

.third-row-column-2 {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
}
.fourth-row {
    padding-top: 20px;
}

.insights-text-ai-large {
    font-size: x-large;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    text-align: left;
}

.insights-text-ai-small {
    font-size: large;
    padding: 0 60px 0 60px;
}

.insights-loading {
    font-size: 18px;
    text-align: center;
    color: #333;
    padding: 20px;
}

.second-row-left-side {
    display: flex;
    flex-direction: column;
    height: 100%
}

.insight-peer-feedback {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #f4e7f4;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.insight-peer-feedback-text {
    font-style: italic;
}

.insight-peer-feedback-person {
    text-align: end;
    font-size: small;
}

.insights-text {
     display: flex;
    flex-direction: column;
    justify-content: center;
}

.insights-text-large {
    color: #455071;
    font-size: 32px;
    font-family: "Manrope", serif;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 20px;
    margin-right: 5px;
}

.insights-text-small {
    color: #455071;
    font-size: 24px;
    font-family: "Manrope", serif;
    line-height: 31px;
}

.insights-image-container {
    width: 457px;
    height: 343px;
    border-radius: 8px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}