/* General Feedback Styles */
.general-feedback-card {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #f4e7f4;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  min-width: 1096px;
  height: 973px;
}

/* Container for the two columns */
.general-feedback-container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  height: 100%;
}

/* Column styling for strengths and growth areas */
.feedback-column {
  width: 48%;
  height: 100%;
  overflow-y: auto;
}

.feedback-column p {
  margin-bottom: 10px;
}

/* Styling for the feedback titles (Strengths, Growth Areas) */
.general-feedback-title {
  font-size: 32px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Manrope', serif;
  line-height: 42px;
}

/* Styling for individual feedback text */
.feedback-text {
  text-align: center;
  color: #030303;
  font-size: 15px;
  font-family: 'Manrope', sans-serif;
  line-height: 20px;
}
