.notification-settings-form-group {
  margin-bottom: 15px;
}

.notification-settings-form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.notification-settings-form-group input[type="checkbox"] {
  margin-right: 10px;
}

.notification-settings-button {
  cursor: pointer;
  padding: 10px;
  width: 200px;
  height: 36px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #0000ff;
  color: #ffffff;
  font-size: 14px;
  font-family: "Arial", sans-serif;
  outline: none;
  margin-top: 30px;
}
