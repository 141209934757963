.carousel-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.carousel-container {
    max-width: 80%; 
    width: 80vw; 
    margin: 0 auto; 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}
.carousel-slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%; 
}

.carousel-slide {
    min-width: 25%; 
    flex-shrink: 0;
    padding: 10px;
    box-sizing: border-box;
}

.carousel-controls {
    display: flex;
    align-items: center;
    gap: 15px; 
  }
  
.prev-btn, .next-btn {
    background-color: white;
    color: black;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    border-radius: 50%;
    font-size: 24px;
    width: 50px;
    height: 50px;
    position: absolute;
}
  
.next-btn {
    right: 0px;
}

.prev-btn {
    left: 0px;
}