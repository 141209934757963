/* GENERAL */

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');

/* Icons */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

:root {
    /* Font styling */
    --font-family: "Manrope",
        sans-serif;
    --color: #3d3d3d;


    /* Colors */
    --highlighting-background-color: #f4e7f4;
    --table-color: #5e17eb;

}

/* INFO AND ACTION SECTION */

.review-starter {
    width: 90%;
    min-height: 25%;
    margin: 2em auto 2em auto;
    display: flex;
    flex-direction: row;
}

.review-starter-header,
.feedback-btn {
    font-family: 'Manrope', sans-serif;
}

.review-starter-header {
    font-weight: 500;
}

.review-starter-info {
    font-family: 'Nanum Gothic', sans-serif;
    color: #455071;
    font-size: 1rem;
    line-height: 1.6;
}

.bg-image {
    width: 20%;
}

.feedback-img-container-comp {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:first center;
    overflow: hidden;
}

.feedback-img-container-comp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.review-starter-info-container {
    width: 70%;
}

/* Buttons */
.feedback-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.feedback-btn {
    cursor: pointer;
    color: #fff;
    font-size: 0.9375rem;
    font-weight: 600;
    text-align: center;
    outline: none;
    box-shadow: -0.125rem 0.125rem 0rem rgba(0, 0, 0, 0.25);;
    background-color: #372ecf;
    border-radius: 0.6875rem;
    border: 0;
    height: 2.6875rem;
    padding: 0 1.2rem;
}

/* FEEDBACK TABLE */
.table-container {
    width: 90%;
    margin: 2em auto 2em auto;
    padding-bottom: 1.5em;;
    border-radius: 1.625rem;
    box-shadow: 0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.16);
}



/* Table header */
.table-header {
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.table-control {
    border: none;
    background-color: #fff;
    cursor: pointer;
    margin-left: 0.7em;
    font-size: 1.5rem;
    height: 100%;
    padding: auto;
}

/* Base table styling */
.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
}

/* Header styling */
.th {
    background-color: var(--highlighting-background-color);
    font-weight: 500;
    text-align: left;
    height: 100%;
}

.tr {
    height: 2.6rem;
}

/* Row and cell styling */
tr:nth-child(even) {
    background-color: #fafafa;
}

/* Checkbox styling */
.checkbox {
    padding-left: 1rem;
}

/* Base style for checkboxes */
.checkbox-input {
    padding-left: 1rem;
    width: 1.5em;
    height: 1.5em;
    background-color: #ffffff;
    border: 2px solid #cccccc;
    border-radius: 0.25em;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    /* Needed for positioning pseudo-elements */
    outline: none;
}

/* Custom checkmark using pseudo-elements for checked state */
.checkbox-input:checked::before {
    content: "";
    /* Needed to create a pseudo-element */
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.3em;
    height: 0.6em;
    border-style: solid;
    border-color: #000000;
    border-width: 0 0.15em 0.15em 0;
    transform: translate(-50%, -50%) rotate(45deg);
    /* Adjust position to center and rotate */
    transform-origin: center center;
    /* Center the transformation origin */
}

/* Checked state styles for the checkbox */
.checkbox-input:checked {
    border-color: #000000;
    background-color: #ffffff;
}

/* Styles for report column */
.report-cell {
    height: 100%;
}

.feedback-bold-row {
    font-weight: 700;
}

/* ICONS */
/* Visibility icon */
.icon {
    height: 100%;
    text-align: center;
    cursor: pointer;
}
.review-sort-icons {
    font-family: 'Material Icons';
    display: inline-block;
    font-style: normal;
    vertical-align: middle;
}


/* Responsive adjustments */
@media (max-width: 600px) {

    .td,
    .th {
        padding: 8px 10px;
    }
}


.container {
    display: flex;
    direction: row;
    max-width: 1200px;
    min-width: 960px;
}

.feedback {
    height: 100%;
    width: 100%;
    margin: auto;
}

/* PAGINATION */
.review-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    font-size: 1rem;
    color: #878ca1;
}

.review-pagination button {
    border: none;
    background: none;
    padding: 0.5em 1em;
    margin: 0 0.2em;
    cursor: pointer;
}

.review-pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.review-pagination input {
    margin: 0 10px;
    padding: 3px;
    width: 100px;
}

.review-pagination select {
    padding: 5px 10px;
    margin-left: 10px;
}

.review-pagination {

}

.review-page-status {
    margin: 0 1em;
}

.review-pagination-icons { 
    font-family: 'Material Icons';
    font-size: 1.2rem;
    color: var(--table-color);
}


/* REQUEST REVIEW */

  .review-request-icon-text,
  .review-request-icon-header {
      display: flex;
      align-items: center;
      margin-top: 0.7rem;
  }

  .review-request-icon-text .onboarding-button,
  .review-request-icon-text .onboarding-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: #7c7c80;
    fill: #7c7c80;
    font-size: 0.875rem;
    height: 1.2rem;
  }

  .review-request-actions .onboarding-button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 600;
    font-family: "Open Sans";
  }

  .review-request-actions {
      display: flex;
      align-items: center;
      margin-top: 2rem;
  }

  .review-request-note .onboarding-text{
      text-align: center;
      font-family: "Open Sans";
      font-weight: 300;
      color: #7c7c80;
      font-size: 1rem;
      line-height: 1.2em;
  }

  .review-request-card-title {
      font-size: 1.8rem;
      font-weight: 700;
      font-family: var(--font-family);
  }

  .request-feedback-overlay {
    position: fixed;
    /* Position relative to parent component */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(238, 220, 238, 0.5);
    /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .request-feedback-container {
      position: relative;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      width: 100%;
      /* adjust width as needed */
      max-width: 50%;
  }

  .review-request-remove,
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .close-button {
      position: absolute;
      top: 10px;
      right: 10px;      
  }

  .review-request-remove {
      color: #7c7c80;
      width: 35px;
      height: 40px;
  }

.review-request-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.review-request-row .onboarding-input-field,
.review-request-row Select,
.review-request-card-text {
    background-color: #f3f3f5;
    font-family: var(--font-family);
    color: #c2c2c2;

}

.review-request-card {
    margin: 1rem;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 2em;
    padding-bottom: 2em;
}

.onboarding-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    pointer-events: none;
}

.error-message {
    color: var(--table-color);
    font-size: 0.875em;
    margin-top: 4px;
    width: 100%;
    text-align: center;
}

.general-error-message {
    color: var(--table-color);
    font-size: 0.875em;
    margin-bottom: 10px;
    text-align: center;
}

@media (max-width: 1420px) {
    .review-request-row {
        flex-direction: column;
    }

    .review-request-row .onboarding-input-field,
    .review-request-row Select,
    .review-request-remove {
        width: 100%;
    }
}


/* ERRORS, INFO MESSAGES */
.feedback-error {
    font-family: 'Nanum Gothic', sans-serif;
    color: var(--table-color);
    width: 50%;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}