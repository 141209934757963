.dashboard-container {
  width: 100%;
  height: 100vh;
  transform: scale(0.8);
  margin-top: -3em;
}

.dashboard-main {
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
}

.dashboard-header {
  color: #4a4c4d;
  font-size: 46px;
  font-family: "Manrope";
  font-weight: 300;
  line-height: 69px;
  margin-bottom: 20px;
}

.dashboard-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 20px;
}

.dashboard-subheader {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.dashboard-greeting-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.dashboard-profile-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.dashboard-profile-picture {
  width: 200px;
  height: 200px;
  margin-right: 10px;
}

.dashboard-goalProgress {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard-goal {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  max-height: 60px;
}

.dashboard-goal-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-goal-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dashboard-goal-title {
  font-size: 16px;
  font-weight: bold;
}

.dashboard-goal-subtitle {
  font-size: 14px;
  color: #3d3d3d;
}

.dashboard-progress-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: conic-gradient(
    var(--circle-color) 0 var(--progress, 0),
    #eee var(--progress, 0) 360deg
  );
  color: black;
  font-weight: bold;
}

.dashboard-profileCard {
  width: 504px;
  height: 184px;
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #f4e7f4;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin-left: 20px;
}

.dashboard-actionCards {
  height: auto; 
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center;
  margin: 20px 0;
}

.card-bg-1 {
  background-image: url("https://assets.api.uizard.io/api/cdn/stream/ab70a136-0911-4d30-93d9-09b9d413ef55.png");
}

.card-bg-2 {
  background-image: url("https://assets.api.uizard.io/api/cdn/stream/46ca5959-3f5c-4824-a99b-0bb5757756d9.png");
}

.card-bg-3 {
  background-image: url("https://assets.api.uizard.io/api/cdn/stream/f29c07d2-8e70-4810-9703-2c7bf69112d0.png");
}

.card-bg-4 {
  background-image: url("https://assets.api.uizard.io/api/cdn/stream/aaf5e4ba-9888-4130-ad21-98bdb253da11.png");
}

.actionCard {
  display: flex;
  justify-content: flex-start;
  width: 250px;
  height: 367px;
  border-radius: 12px;
  border: 1px solid #ededf3;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: center;
  margin: 15px;
  padding: 20px; 
  overflow: hidden; 
  text-align: center; 
}

.actionCard-title {
  color: #3d3d3d;
  font-size: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  margin-bottom: 10px;
  text-align: center;
}

.actionCard-details {
  color: #3d3d3d;
  font-size: 16px;
  font-family: "Manrope";
  text-align: center;
}

.dashboard-goal {
  width: 304px;
  height: 80px;
  background-color: #f4f8fb;
  border-radius: 10px;
  border: 1px solid #f4e7f4;
  box-sizing: border-box;
  box-shadow: 4px 3px 10px rgba(3, 3, 3, 0.1);
}

.dashboard-button {
  cursor: pointer;
  padding: 10px;
  width: auto;
  height: 36px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #0000ff;
  color: #ffffff;
  font-size: 14px;
  font-family: "Arial", sans-serif;
}

.dashboard-aboutMe {
  color: #3d3d3d;
  font-size: 12px;
}

.quote-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 367px;
  border-radius: 12px;
  border: 1px solid #ededf3;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  margin: 15px;
  padding: 10px;
  overflow: hidden;
  text-align: center;
}

.quote-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.quote-message {
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Manrope";
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

.quote-author {
  width: 100%;
  display: block;
  color: #3d3d3d;
  font-size: 16px;
  font-family: "Manrope";
  font-weight: 400;
  font-style: italic;
  text-align: right;
  margin-top: 10px;
}
