.insight-performance-summary-container {
    width: auto;
    height: auto;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    text-align: center;
    margin-bottom: 40px
}


.insight-performance-summary-header {
    text-align: left;
    font-size: x-large;
    font-family: Arial, sans-serif;
    padding-bottom: 15px;
}
