/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');

:root {
  /* Colors */
  --primary-color: #0000ff;
  --secondary-color: #ffffff;
  --border-color: #e0e0e0;
  --text-color: #333333;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --highlight-color: #384168;
  --info-content-color: #455071;
  --font-family-header: 'Manrope', sans-serif;
  --font-family-content: 'Nanum Gothic', sans-serif;
}

.growth-library-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 10px;
  border: 1px solid var(--border-color);
  box-shadow: 0px 4px 8px var(--shadow-color);
  padding: 20px;
  width: 90%;
  max-width: 300px;
  min-width: 300px;
  height: auto;
  box-sizing: border-box;
}

.growth-card-image {
  width: 100%;
  max-width: 270px;
  width: auto;
  height: 149px;
  object-fit: cover;
  border-radius: 8px;
}

.growth-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  height: 100%;
}

.growth-card-title {
  font-size: 14px;
  font-weight: bold;
  color: var(--highlight-color);
}

.growth-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.growth-card-price {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color);
}

.growth-card-icon {
  width: auto;
  height: 24px;
}

.growth-card-button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--primary-color);
}

.growth-card-button:hover {
  border: 1px solid var(--primary-color);
  color: var(--highlight-color);
  background-color: var(--secondary-color);
}

.growth-view-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.growth-view-more-button {
  background-color: transparent;
  border: 1px solid var(--text-color);
  color: var(--info-content-color);
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 2px 4px var(--shadow-color);
  transition: all 0.3s ease;
  margin-bottom: 3rem;
}

.growth-view-more-button:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: 0;
}

.growth-library-parent {
  display: flex;
  justify-content: center;
}

.growth-library-container {
  width: 90%;
  max-width: 1200px;
}

.growth-library-info-content-header {
  font-family: var(--font-family-header);
  font-weight: 600;
  margin-bottom: 0;
}

.growth-library-info-content-paragraph {
  font-family: var(--font-family-content);
  color: var(--info-content-color);
  font-size: 1rem;
  line-height: 1.6;
}

.growth-library-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.growth-library-info-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.growth-library-info-container .feedback-img-container-comp img {
  width: 100px;
  height: auto;
  margin-right: 20px;
}

.growth-library-info-content {
  display: flex;
  flex-direction: column;
}

.growth-library-card-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 3rem;
  width: 100%;
  padding-bottom: 3rem;
}

.growth-view-more-container {
  margin-top: 20px;
}

.growth-view-more-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

/* ERRORS */
.growth-library-message {
  font-family: 'Nanum Gothic', sans-serif;
    color: var(--table-color);
    width: 50%;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

/* FILTER */
.growth-library-tag-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.growth-library-tag-buttons-container button{
  background-color: #eddfed;
  font-family: Open Sans;
  font-size: 14px;
  color: #50577e;
  border: none;
  padding: 10px 15px;
  margin: 1rem;
  border-radius: 22px;
  cursor: pointer;
  border: 1px solid #eddfed;
}

.growth-library-tag-buttons-container button.active {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.growth-library-filter-label{
  font-size: 18px;
  font-weight: 600;
  font-family: Open Sans;
  color: #1b2852;
}

.growth-card-header {
  display: flex;
  align-items: center;
  justify-content: center;  /* Centers the image container */
  position: relative;
  height: 150px;
  width: 100%;
}

.growth-card-image-container {
  position: relative;
  height: 150px;
  width: auto;
}

.growth-card-ellipsis {
  position: absolute;
  top: 4px;
  right: -15px;
}

.ellipsis-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.custom-dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  list-style: none;
  padding: 5px 0;
  margin: 0;
  min-width: 150px;
  z-index: 1;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.marked-unwanted {
  /* Keep same dimensions as normal card */
  width: 90%;
  max-width: 300px;
  min-width: 300px;
  height: 300px; /* Match height of normal card */
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.unwanted-message {
  color: #666;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem; /* Increased gap between text and button */
  text-align: center;
}

.unwanted-text {
  font-size: 1rem;
  font-weight: 250;
}

.undo-button {
  /* Match sidebar style */
  background-color: #eddeed;
  color: #333333;
  border: none;
  padding: 8px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-family: Manrope, sans-serif;
  transition: background-color 0.3s;
}

.undo-button:hover {
  background-color: #e0d0e0;
}

.feedback-mode {
  width: 300px;
  height: 300px;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;
}

.feedback-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-out;
}

.feedback-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
}

.feedback-input {
  width: 90%;
  height: 100px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  font-family: inherit;
}

.feedback-buttons {
  display: flex;
  gap: 1rem;
}

.submit-button, .cancel-button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: inherit;
}

.submit-button {
  background-color: #eddeed;
  color: #333;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #e0d0e0;
}

.submit-button:disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}

.cancel-button {
  background-color: #f0f0f0;
  color: #666;
  transition: background-color 0.3s;
}

.cancel-button:hover {
  background-color: #e4e4e4;
}

.feedback-thank-you {
  font-size: 1.1rem;
  color: #333;
  font-weight: 500;
  text-align: center;
  animation: fadeOutAndHide 2s ease-in-out forwards;
}

@keyframes fadeOutAndHide {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
