.account-settings {
    display: flex;
    font-family: "Manrope";
    height: 100vh;
}

.account-settings-container{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.account-settings-user-info img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.account-settings-profile {
    flex-grow: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: justify;
}

.account-settings-profile-image{
    margin-left: 20px;
}

.account-settings-profile form {
    width: 100%;
    max-width: 800px;
    background: #fff;
    margin: 20px;
}

.account-settings-tabs {
    display: flex;
    margin-top: 20px;
}

.account-settings-tab-button {
    display: flex;
    align-items: justify;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    margin-left: 10px;
    margin-right: 50px;
    margin-bottom: 0px;
}

.account-settings-tab-highlighted{
    font-weight: bold;
}

.account-settings-tab-button svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
}

.account-settings-button {
    cursor: pointer;
    padding: 10px;
    width: 200px;
    height: 36px;
    border: 0;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #0000ff;
    color: #ffffff;
    font-size: 14px;
    font-family: "Manrope";
    outline: none;
    margin-top: 30px;
}

.account-settings-form-group-row {
    display: flex;
    justify-content: space-between;
}

.account-settings-form-group {
    flex: 1;
    margin-right: 20px;
}

.account-settings-form-group:last-child {
    margin-right: 0;
}

.account-settings-form-group label {
    display: block;
}

.account-settings-form-group input,
.account-settings-form-group textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #d6d6db;
    box-sizing: border-box;
    border-radius: 10px;
}

.account-settings-form-label{
    margin-top: 20px;
}
