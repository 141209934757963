/* Competency Summary Card Styles */

.competency-summary-card {
    min-width: 540px;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #f4e7f4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 20px;
    flex-grow: 1;
    flex-shrink: 0;
    box-sizing: border-box;
}

.competency-title {
    font-size: 28px;
    font-weight: 500;
    color: #030303;
    text-align: left;
    font-family: 'Manrope';
    line-height: 36px;
    margin-bottom: 20px;
}

.competency-grid {
    display: grid;
    grid-template-columns: 2fr 1fr repeat(6, minmax(48px, 1fr));
    gap: 5px;
}

.rotated-label {
    transform: rotate(-90deg);
    transform-origin: center;
    font-size: 12px;
    font-family: 'Manrope';
    color: #4a4c4d;
    text-align: center;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
}

.competency-label {
    font-size: 14px;
    color: #030303;
    line-height: 18px;
    font-family: 'Manrope';
    text-align: left;
}

.competency-score {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #030303;
    line-height: 36px;
    font-family: 'Manrope';
}

.competency-box {
    min-width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
}

.development-needed { background-color: #f7625e; }
.progressing { background-color: #ff9e3a; }
.competent { background-color: #ffd700; }
.strong { background-color: #90ea84; }
.outstanding { background-color: #66cc66; }
.missing-value { background-color: #d3d3d3; }

.competency-legend {
    display: grid;
    grid-template-columns: repeat(5, minmax(90px, 1fr));
    gap: 10px;
    margin-top: 20px;
}

.legend-label {
    font-size: 14px;
    text-align: center;
    color: #4a4c4d;
    font-family: 'Manrope', sans-serif;
}

.legend-box {
    min-width: 90px;
    height: 20px;
    border-radius: 4px;
}