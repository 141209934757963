.gauge-container {
  text-align: center;
  font-family: Arial, sans-serif;
}

.gauge {
  transform: rotate(0deg);
}

.gauge-value {
    font-size: 30px;
    font-weight: bold;
    position: relative;
    top: -20px;
}

.gauge-label {
  font-size: 16px;
  color: black;
}

.gauge-labels {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    font-size: 10px;
    color: black;
    padding-right: 30px;
    position: relative;
    top: -90px;
    right: 12px;
}
