.attributes-card {
    min-width: 704px;
    max-height: 2420px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #f4e7f4;
    box-sizing: border-box;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
    padding: 20px;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin-bottom: 20px;
}

.attributes-title {
    font-size: 32px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    color: #333;
    text-align: left;
    line-height: 42px;
}

.attributes-section-title {
    font-size: 19px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    color: #333;
    margin-top: 20px;
    line-height: 25px;
}

.attributes-subsection-label {
    font-size: 15px;
    line-height: 20px;
    font-family: 'Manrope', sans-serif;
    color: #555;
    margin-top: 20px;
    margin-bottom: 30px;
}

.attributes-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns for the boxes */
    grid-template-rows: auto auto; /* Two rows: one for boxes, one for descriptions */
    gap: 10px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.attributes-box {
    min-width: 90px;
    height: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    margin-bottom: 5px;
}

.attributes-development-needed {
    background-color: #f7625e;
}

.attributes-progressing {
    background-color: #ff9e3a;
}

.attributes-competent {
    background-color: #ffd700;
}

.attributes-strong {
    background-color: #90ea84;
}

.attributes-outstanding {
    background-color: #66cc66;
}

.attributes-box-description {
    text-align: center;
    font-size: 9px;
    line-height: 12px;
    font-family: 'Manrope', sans-serif;
    color: #4a4c4d;
}

.attributes-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #4169e1;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    margin-top: -30px;
}

.attribute-item {
    display: flex;
    flex-direction: column;
}

.initials-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
    margin-bottom: 10px;
    justify-items: stretch;
}

.initials-row-development {
    display: flex;
}

.initials-row-progressing {
    display: flex;
}

.initials-row-competent {
    display: flex;
}

.initials-row-strong {
    display: flex;
}

.initials-row-outstanding {
    display: flex;
}

.initials-box-development {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.initials-box-progressing {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.initials-box-competent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.initials-box-strong {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.initials-box-outstanding {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.initials {
    width: 24px;
    height: 24px;
    background-color: #4169e1;
    border-radius: 50%;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope', sans-serif;
}
